<template>
	<el-dialog title="确认完成" :close-on-click-modal="false" :visible.sync="visible"
		width="600px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true">
			<el-form-item label="完成时间" prop="contractTime">
				<el-date-picker  class="selItemInput"
				  v-model="dataForm.contractTime"
				  type="date"
				  value-format="yyyy-MM-dd"
				  placeholder="选择日期">
				</el-date-picker>
			</el-form-item>
		</el-form>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">取消</el-button>
			<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
		</el-row>
	</el-dialog>

</template>

<script>
	import $common from "@/utils/common.js"
	export default {
		name: "ownerContract-update-finish",
		data() {
			return {
				visible: false,
				inline: false,
				dataForm: {
					contractId:"",
					finishDate:"",
				},
				dataRule: {
					finishDate: [
						{ required: true, message: "完成时间不能为空", trigger: "blur" }
					]
				},
			}
		},
		methods: {
			init(id) {
				this.dataForm = {
					contractId:"",
					finishDate:"",
				};
				this.dataForm.contractId = id?id:0;
				this.$nextTick(() => {
					this.visible = true;
				})
			},
			dataFormSubmit() {
			  this.$refs["dataForm"].validate(valid => {
				if (valid) {
				  this.$http({
					url: this.$store.state.httpUrl + "/business/ownercontractinfo/updateFinish",
					method: "post",
					data: this.$http.adornData(this.dataForm)
				  }).then(({ data }) => {
					if (data && data.resultCode === 200) {
					  this.$message({
						message: "操作成功",
						type: "success",
						duration: 1500,
						onClose: () => {
						  this.visible = false;
						  this.$emit("refreshDataList");
						}
					  });
					} else {
					  this.$message.error(data.msg);
					}
				  });
				}
			  });
			}
		}
	}
</script>
<style lang="scss"  scoped>
	.selItemInput.descAreaLg{
		width: 718px !important;
	}
</style>
