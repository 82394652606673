<template>
	<!-- 报检汇总 -->
	<div class="mod-config">
		<el-card body-style="padding-bottom: 0;" style="margin-top: -5px;">
			<div class="title">合同列表</div>
			<div class="line"></div>
			<el-row style="margin-bottom: 5px;">
				<el-col :span="pageSpan" v-if="showAdd">
					<el-button v-preventReClick class="btn" type="primary" size="small"
						@click="addOrUpdateHandle()">添加</el-button>
				</el-col>
			</el-row>
			<div class="line"></div>
			<el-form ref="form" :model="form" inline label-width="70px" label-position="left">
				<el-row
					style="background-color: rgb(243, 247, 247);margin-top: -10px;margin-left: -20px;height: 45px;width: 103%;">
					<el-col :span="19" style="margin-left:20px">
						<el-form-item label="签订单位" class="selFormItem">
							<el-input class="selSearchInput" v-model="contractCompany" type="input" rows="1"
								style="width:200px" placeholder="请填写"></el-input>
						</el-form-item>
						<el-form-item label="签订时间" class="selFormItem">
							<el-date-picker class="selDateInput" v-model="contractTime" type="daterange"
								value-format="yyyy-MM-dd" range-separator="~" start-placeholder="开始日期"
								end-placeholder="结束日期">
							</el-date-picker>
						</el-form-item>
						<el-form-item label="状态" class="selFormItem">
							<el-tag type="info" class="infoTag1" :class="{ 'active': stateFlg == item.displayValue }"
								v-for="item in contratStateList" @click="setStateFlg(item.displayValue)">{{ item.displayName
								}}</el-tag>
						</el-form-item>
					</el-col>
					<el-col :span="4" class="selBtnGroup" style="margin-top:5px">
						<el-button v-preventReClick class="btn" type="primary" size="small" @click="selGetDataList()"
							v-if="showSearch">检索</el-button>
						<el-button v-preventReClick class="rebtn" size="small" @click="resetFormData()">重置</el-button>
					</el-col>
				</el-row>
			</el-form>
		</el-card>
		<div>
			<el-card style="margin-top: -25px; height: 550px;" body-style="height:510px; padding: 0;">
				<el-row style="height: 100%; margin-bottom: 10px;">
					<el-col :span="24" style="height: 100%;">
						<div class="box">
							<el-table height="490" :row-class-name="tableRowClassName" class="dataListTable" border
								:data="dataList" header-align="center" style="width: 100%;margin-bottom: 20px;"
								header-cell-class-name="stepPointTHeader" :cell-style="{ padding: '0px' }">
								<el-table-column type="index" label="NO." width="60" align="center">
									<template slot-scope="scope">{{ (pageIndex2 - 1) * pageSize + scope.$index + 1
									}}</template>
								</el-table-column>
								<el-table-column prop="contractNo" label="合同编号" width="100" align="center">
								</el-table-column>
								<el-table-column prop="contractName" label="合同名称" width="170" align="center"
									show-overflow-tooltip>
								</el-table-column>
								<el-table-column prop="contractTime" label="签订时间 " width="110" align="center">
								</el-table-column>
								<el-table-column prop="contractCompany" label="签订单位" align="left" header-align="center"
									show-overflow-tooltip>
								</el-table-column>
								<el-table-column prop="beginTime" label="开始时间 " width="110" align="center">
								</el-table-column>
								<el-table-column prop="endTime" label="结束时间 " width="110" align="center">
								</el-table-column>
								<el-table-column prop="changeTimes" label="变更次数" width="90" align="center">
								</el-table-column>
								<!--					  <el-table-column prop="contractAmount" label="合同金额" align="center">-->
								<!--					  </el-table-column>-->
								<!--					  <el-table-column prop="contractContent" label="合同内容" align="center">-->
								<!--					  </el-table-column>-->
								<el-table-column prop="stateFlgName" label="状态" width="100" align="center">
								</el-table-column>
								<el-table-column label="操作" width="300" fixed="right"
									v-if="showChange || showPayment || showEdit || showRun || showFinish || showLook"
									align="center">
									<template slot-scope="scope">
										<el-button v-preventReClick type="text" size="small"
											@click="changedLogHandle(scope.row.contractId)"
											v-if="showChange && scope.row.stateFlg != 2">合同变更</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="paymentLogHandle(scope.row.contractId)"
											v-if="showPayment && scope.row.stateFlg != 2">付款记录</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="addOrUpdateHandle(scope.row.contractId)"
											v-if="showEdit && scope.row.stateFlg != 2">修改</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="updateStartHandle(scope.row.contractId)"
											v-if="showRun && scope.row.stateFlg == 0">执行</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="updateFinishHandle(scope.row.contractId)"
											v-if="showFinish && scope.row.stateFlg == 1">完成</el-button>
										<el-button v-preventReClick type="text" size="small"
											@click="lookInfoHandle(scope.row.contractId)" icon="el-icon-paperclip"
											v-if="showLook">详情</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<el-pagination class="my-pagination" @size-change="sizeChangeHandle"
							@current-change="currentChangeHandle" :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]"
							:page-size="pageSize" :total="totalPage" layout="total, sizes, prev, pager, next, jumper">
						</el-pagination>
					</el-col>
				</el-row>
			</el-card>
		</div>
		<!-- 弹窗, 新增节点 -->
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList()"></add-or-update>
		<update-finish v-if="updateFinishVisible" ref="updateFinish" @refreshDataList="getDataList()"></update-finish>
		<!-- <upload-file v-if="uploadFileVisible" ref="uploadFile" @refreshDataList="getDataList()"></upload-file> -->
		<changed-log v-if="changedLogVisible" ref="changedLog" @refreshDataList="getDataList()"></changed-log>
		<payment-log v-if="paymentLogVisible" ref="paymentLog" @refreshDataList="getDataList()"></payment-log>
		<!--	  <contract-info v-if="contractInfoVisible" ref="contractInfo" @refreshDataList="getDataList()"></contract-info>-->
	</div>
</template>
<script>
import AddOrUpdate from './ownerContract-add-or-update'
import UpdateFinish from './ownerContract-update-finish'
// import UploadFile from './ownerContract-upload-file'
import ChangedLog from './ownerContract-changed-log'
import PaymentLog from './ownerContract-payment-log'
// import ContractInfo from './ownerContractInfo'
import $common from "@/utils/common.js"
export default {
	data() {
		return {
			showSearch: false,
			showAdd: false,
			showEdit: false,
			showDelete: false,
			showChange: false,
			showPayment: false,
			showFinish: false,
			showRun: false,
			showLook: false,
			pageSpan: 12,
			form: {},
			addOrUpdateVisible: false,
			updateFinishVisible: false,
			uploadFileVisible: false,
			changedLogVisible: false,
			paymentLogVisible: false,
			contractInfoVisible: false,
			contractCompany: "",
			contractTime: [],
			contratStateList: [],
			stateFlg: "",
			dataList: [],
			//当前页码
			pageIndex: 1,
			// 显示序号
			pageIndex2: 1,
			//当前记录数
			pageSize: 20,
			//总页数
			totalPage: 0,
		};
	},
	components: {
		AddOrUpdate,
		UpdateFinish,
		// UploadFile,
		ChangedLog,
		PaymentLog,
		// ContractInfo
	},
	mounted() {
		this.showSearch = $common.isAuth('ownerContract:search');
		this.showAdd = $common.isAuth('ownerContract:add');
		this.showEdit = $common.isAuth('ownerContract:edit');
		this.showDelete = $common.isAuth('ownerContract:delete');
		this.showChange = $common.isAuth('ownerContract:change');
		this.showPayment = $common.isAuth('ownerContract:payment');
		this.showFinish = $common.isAuth('ownerContract:finish');
		this.showRun = $common.isAuth('ownerContract:run');
		this.showLook = $common.isAuth('ownerContract:look');
		if (!this.showAdd) {
			this.pageSpan = 24;
		}
		this.getDataList();
		this.getContratState();
	},
	methods: {
		// 新增检验项目
		addOrUpdateHandle(id) {
			this.addOrUpdateVisible = true
			this.$nextTick(() => {
				this.$refs.addOrUpdate.init(id)
			})
		},
		// 变更记录
		changedLogHandle(id) {
			this.changedLogVisible = true;
			this.$nextTick(() => {
				this.$refs.changedLog.init(id);
			})
		},
		lookInfoHandle(id) {
			// this.contractInfoVisible = true;
			// this.$nextTick(() => {
			//   this.$refs.contractInfo.init(id);
			// })
			this.$router.push({ name: 'ownerContractInfo', params: { contractId: id } });
		},
		// 收款记录
		paymentLogHandle(id) {
			this.paymentLogVisible = true;
			this.$nextTick(() => {
				this.$refs.paymentLog.init(id);
			})
		},
		// 确认
		updateFinishHandle(id) {
			this.updateFinishVisible = true
			this.$nextTick(() => {
				this.$refs.updateFinish.init(id)
			})
		},
		selectFile(url) {
			window.open(url);
		},
		// uploadFileHandle(id){
		//  this.uploadFileVisible = true
		//  this.$nextTick(() => {
		// this.$refs.uploadFile.init(id)
		//  })
		// },
		setStateFlg(value) {
			if (this.stateFlg != value) {
				this.stateFlg = value;
			} else {
				this.stateFlg = "";
			}
		},
		siteContentViewHeight() {
			var height = this.$store.state.common.documentClientHeight - 260;
			return { height: height + 'px' }
		},
		handleSelectionChange(val) {
			this.tableSelVal = val;
		},
		tableRowClassName({ row, rowIndex }) {
			let color = ''
			for (let item of this.tableSelVal) {
				if (item.id === row.id) color = 'table-SelectedRow-bgcolor'
			}
			return color
		},
		currentChangeHandle(val) {
			this.pageIndex = val
			this.getDataList()
		},
		// 每页数
		sizeChangeHandle(val) {
			this.pageSize = val
			this.pageIndex = 1
			this.getDataList()
		},
		getContratState() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getContratState",
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.contratStateList = data.body;
				}
			});
		},
		getDataList() {
			if (this.showSearch) {
				this.$http({
					url: this.$store.state.httpUrl + "/business/ownercontractinfo/list",
					method: "get",
					params: {
						workNo: $common.getItem("workNo"),
						subjectNo: $common.getItem("subjectNo"),
						beginTime: this.contractTime[0] ? this.contractTime[0] : "",
						endTime: this.contractTime[1] ? this.contractTime[1] : "",
						contractCompany: this.contractCompany,
						stateFlg: this.stateFlg,
						limit: this.pageSize,
						page: this.pageIndex
					},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.dataList = data.body.records;
						this.totalPage = data.body.totalCount;
						this.pageIndex2 = this.pageIndex
					}
				});
			}
		},
		resetFormData() {
			this.contractCompany = "";
			this.contractTime = [];
			this.stateFlg = "";
		},
		selGetDataList() {
			this.pageIndex = 1;
			this.getDataList();
		},
		updateStartHandle(id) {
			this.$confirm(`确定进行执行合同操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/ownercontractinfo/start/" + id,
					method: 'post',
					data: {}
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				})
			})
		},
		tableRowClassName({ row, rowIndex }) {
			return $common.tableRowClassName({ row, rowIndex });
		},
	}
};
</script>
<style scoped>
.selItemText {
	font-size: 14px;
	color: #1c1c1c;
	display: inline-block;
	width: 80px;
}

.my-pagination {
	margin-right: 20px !important;
}


::v-deep(.selSearchInput .el-input__inner) {
	width: 150px !important;
	height: 30px !important;
}

.selDateInput {
	width: 280px !important;
	margin-top: 2px;
	height: 32px;

	::v-deep .el-input__inner {
		height: 33px;
	}
}

::v-deep(.selDateInput .el-range-separator) {
	width: 40px !important;
	margin-top: -8px;
}

::v-deep(.selFormItem .el-form-item__label) {
	font-size: smaller;
}

.infoTag1 {
	background-color: transparent !important;
	border-color: #00428e !important;
	color: #00428e !important;
	line-height: 30px !important;
	height: 30px !important;
	padding: 0 15px !important;
	min-width: 80px;
	font-size: 12px !important;
	text-align: center;
	cursor: pointer;
}

.infoTag1.active {
	background-color: #00428e !important;
	color: #ffffff !important;
}

.infoTag1~.infoTag1 {
	margin-left: 10px;
}</style>
